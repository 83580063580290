import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, LogManager } from 'aurelia-framework';
import { ForgotPasswordTo } from 'models/forgotPasswordTo';
import { LoginData } from 'models/LoginData';
import { ResetPasswordDto } from 'models/resetPasswordDto';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';
import { UserService } from 'services/user-service';
import internal from 'stream';
import { Logger } from 'aurelia-logging';
import {
  AuthenticationFailedResponse,
  AuthenticationResponse,
} from 'models/Authentication';

const logger: Logger = LogManager.getLogger('account-service');

@autoinject
export class AccountService {
  private baseUrl: string = 'api/account';
  private authToken: string = '';

  constructor(
    private httpClient: HttpClient,
    private eventAggregator: EventAggregator
  ) {
    if (localStorage.getItem('authToken')) {
      this.authToken = localStorage.getItem('authToken');
    }
  }

  public getAuthToken(): string {
    return this.authToken;
  }

  public clearAuthToken() {
    if (localStorage.getItem('authToken')) {
      this.authToken = null;
      localStorage.removeItem('authToken');
    }
  }

  // TODO: validate token
  public isAuthenticated() {
    if (this.authToken) {
      return true;
    } else {
      return false;
    }
  }

  public login(
    loginData: LoginData
  ): Promise<AuthenticationResponse | AuthenticationFailedResponse> {
    return this.httpClient
      .fetch(this.baseUrl + '/login', {
        method: 'POST',
        body: JSON.stringify(loginData),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((res) => {
        if (res.status < 400) {
          return res.text().then((responseText) => {
            const result: {
              MfaRequired: boolean;
              MfaProviders?: string[];
              Token?: string;
            } = JSON.parse(responseText);

            if (result.MfaRequired) {
              return {
                mfaRequired: true,
                mfaProviders: result.MfaProviders,
              };
            } else {
              this.authToken = result.Token;
              localStorage.setItem('authToken', this.authToken);

              this.eventAggregator.publish('auth_token_updated', {
                token: this.authToken,
              });

              return { loggedIn: true };
            }
          });
        } else {
          return res.json().then((data: { noValidProviders: boolean }) => {
            return { loggedIn: false, ...data };
          });
        }
      });
  }

  public logout(): Promise<any> {
    return this.httpClient
      .fetch(this.baseUrl + '/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((res) => {
        if (res.status < 400) {
          return res.text().then((responseText) => {
            const tokenData = JSON.parse(responseText);
            this.authToken = tokenData.token;
            localStorage.removeItem('authToken');

            this.eventAggregator.publish('auth_token_cleared', {});
            return true;
          });
        } else {
          return false;
        }
      });
  }

  public forgotPassword(forgotPasswordTo: ForgotPasswordTo): Promise<any> {
    return this.httpClient
      .fetch(this.baseUrl + '/forgot-password', {
        method: 'POST',
        body: JSON.stringify(forgotPasswordTo),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((res) => {
        if (res.status <= 400) {
          return;
        } else {
          return res.text().then((responseText) => {
            throw responseText;
          });
        }
      });
  }

  public resetPassword(resetPasswordTo: ResetPasswordDto): Promise<any> {
    return this.httpClient
      .fetch(this.baseUrl + '/reset-password', {
        method: 'POST',
        body: JSON.stringify(resetPasswordTo),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status <= 400) {
          return;
        } else {
          return response.text().then((responseText) => {
            throw responseText;
          });
        }
      });
  }

  public sendMfaCode(provider: string) {
    return this.httpClient
      .fetch(this.baseUrl + '/send-mfa-code', {
        method: 'POST',
        body: JSON.stringify({
          MfaProvider: provider,
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((res) => {
        return res.json().then((d) => {
          if (res.status < 400) {
            return { codeSent: true, MfaToken: d.MfaToken };
          } else {
            return { codeSent: false };
          }
        });
      });
  }

  public mfaLogin(provider: string, code: string, token: string) {
    return this.httpClient
      .fetch(this.baseUrl + '/mfa-login', {
        method: 'POST',
        body: JSON.stringify({
          MfaProvider: provider,
          MfaToken: token,
          Code: code,
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((res) => {
        if (res.status < 400) {
          return res.text().then((responseText) => {
            const result: {
              Success: boolean;
              Token?: string;
            } = JSON.parse(responseText);

            if (result.Success) {
              this.authToken = result.Token;
              localStorage.setItem('authToken', this.authToken);

              this.eventAggregator.publish('auth_token_updated', {
                token: this.authToken,
              });

              return { loggedIn: true };
            } else {
              return { loggedIn: false };
            }
          });
        } else {
          return { loggedIn: false };
        }
      });
  }
}
