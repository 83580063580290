import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class EquipmentTypeComponentService extends BaseApiService<Models.EquipmentTypeComponent> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.EquipmentTypeComponent);
    }

    public setComponentUnit(component: Models.EquipmentTypeComponent) {
        if (component.PriceCalculationTypeId.toString() == '1') {
            component['_unit'] = 'general.qty';
        } else if (component.PriceCalculationTypeId.toString() == '2') {
            component['_unit'] = 'general.m2_short';
        } else if (component.PriceCalculationTypeId.toString() == '3') {
            component['_unit'] = 'general.hours';
        } else {
            component['_unit'] = '';
        }
    } 
}
