import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class AnalysisVariantService extends BaseApiService<Models.AnalysisVariant> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.AnalysisVariant);
    }

    public createVariant(analysisId: number) {
        return this.post(null, '/create-variant/' + analysisId);
    }

    public getVariants(analysisId: number) {
        return this.get('/get-variants/' + analysisId);
    }

    public signAnalysisVariant(analysisVariant: any) {
        return this.post(analysisVariant, '/sign/' + analysisVariant.Id);
    }
    
    public unlockAnalysisVariant(analysisVariantId: any) {
        return this.post(null, '/' + analysisVariantId + '/unlock');
    }
}
