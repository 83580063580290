import { autoinject } from 'aurelia-framework';
import { AnalysisSinkerMaterial } from 'models/AnalysisSinkerMaterial';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class AnalysisSinkerMaterialService extends BaseApiService<AnalysisSinkerMaterial> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, AnalysisSinkerMaterial);
  }
}
