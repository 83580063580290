import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class AuditLogService extends BaseApiService<Models.AuditLog> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.AuditLog);
    }

    public getByEntityOrParent(entityType: string, entityId: number): Promise<Array<any>> {
        return super.getAll(`/get-by-entity-or-parent/${entityType}/${entityId}`);
    }
}
