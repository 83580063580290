import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { CustomerDocumentsHandler, CustomerDocumentsHandlerArgs } from './customer-documents-handler';

@autoinject()
export class AttachmentHandlerFactory {
  constructor(private httpClient: NetlogHttpClient) {}

  public customerAttachmentHandler(args: CustomerDocumentsHandlerArgs) {
    return new CustomerDocumentsHandler(this.httpClient, args);
  }
}
