import { Prompt } from 'elements/prompt';
import { autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';

@autoinject
export class DeleteDialogService {
    constructor(private dialogService: DialogService) {

    }

    public confirmBeforeDelete(runIfConfirm: () => void, header: string = 'dialog.deleteHeading', message: string = 'dialog.deleteConfirmation', deleteButtonText: string = 'dialog.delete') {
        return this.dialogService.open({
            viewModel: Prompt,
            model: {
                header: header,
                message: message,
                actions: {
                    delete: { enabled: true, t: deleteButtonText },
                    save: { enabled: false },
                    cancel: { enabled: true, t: 'dialog.cancel' },
                    dontsave: { enabled: false }
                }
            }
        }).whenClosed((response) => {
            if (response.wasCancelled) {
            return false;
            } else {
            const result = response.output;
            if (result === 'delete') {
                runIfConfirm();
                return false;
            } else {
                return true;
            }
            }
        });
    }
}
