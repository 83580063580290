import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class AnalysisService extends BaseApiService<Models.Analysis> {
  public ANALYSIS_FILTERS_KEY = 'ANALYSIS_FILTERS_KEY';

  public manualFilters = ['top', 'skip'];

  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.Analysis);
  }

  public createAnalysis(analysis: any) {
    return this.post(analysis, '/create-analysis');
  }

  public signAnalysis(analysis: any) {
    return this.post(analysis, '/' + analysis.Id + '/sign');
  }

  public unlockAnalysis(analysisId: any) {
    return this.post(null, '/' + analysisId + '/unlock');
  }

  public getAnalysisUsage(analysisId: any) {
    return this.get(analysisId + '/usage');
  }

  public getNetsLinkedToAnalysis(analysisId: any) {
    return this.get(analysisId + '/linked-nets') as unknown as any;
  }

  public getList(filters: any): Promise<any> {
    let queryString = this.getFilterStringFromActiveFilterFields(filters._activeFilterFields || []);

    let props = Object.getOwnPropertyNames(filters);

    props.forEach((key) => {
      // add querystring params for all criterias, but ignore props that start with _
      if (filters[key] != null && !this.manualFilters.find((x) => x === key) && key.indexOf('_') !== 0) {
        queryString += '&' + key + '=' + filters[key];
      }
    });

    if (filters.searchText) {
      queryString += '&searchText=' + filters.searchText;
    }
    if (filters.orderBy) {
      queryString += '&orderBy=' + filters.orderBy;
    }
    if (filters.orderByDirection) {
      queryString += '&orderByDirection=' + filters.orderByDirection;
    }

    if (filters._select) {
      queryString += '&select=' + filters._select;
    }

    if (filters.signed != undefined) {
      queryString += '&signed=' + filters.signed;
    }

    if (filters._export) {
      queryString += '&export=' + filters._export;

      return this.getBlob('get-analyses' + queryString);
    } else {
      // dont use paging if exporting
      if (filters.top) {
        queryString += '&top=' + filters.top;
      }
      if (filters.skip) {
        queryString += '&skip=' + filters.skip;
      }

      return this.getResponse('get-analyses' + queryString);
    }
  }

  private getFilterStringFromActiveFilterFields(activeFilterFields): string {
    let queryParts = [];

    activeFilterFields.forEach((field) => {
      if (field.activeFilter.value) {
        queryParts.push(
          (field.filterQueryStringKey ? field.filterQueryStringKey : this.pscl2Cml(field.field)) +
            '=' +
            field.activeFilter.value
        );
      }
    });

    return '?' + queryParts.join('&');
  }

  private pscl2Cml(string) {
    return string.substring(0, 1).toLowerCase() + string.substring(1);
  }

  /**
   * Returns analysis that matches the given net.
   *
   * @async
   * @returns Promise<Array<Analysis> found analyses.
   */
  public async getMatchingAnalysisForNet(netId: number) {
    const params = new URLSearchParams();
    params.append('netId', netId?.toString());
    return await this.get(`get-matching-analyses-for-net?${params.toString()}`);
  }

  public async getAnalysisForNet(netId: number, analysisNumber: number) {
    const params = new URLSearchParams();
    params.append('netId', netId?.toString());
    return (await this.get(`get-analyses-for-net/${netId}/${analysisNumber}`)) as unknown as any[];
  }

  /* private getFilterStringFromFilter(filters): string {
    let queryParts = [];

    if (filters.searchText) {
      queryParts.push("searchText=" + filters.searchText);
    }

    if (filters.circumference) {
      queryParts.push("circumference=" + filters.circumference);
    }

    if (filters.depthBottom) {
      queryParts.push("depthBottom=" + filters.depthBottom);
    }

    if (filters.depthBottomCenter) {
      queryParts.push("depthBottomCenter=" + filters.depthBottomCenter);
    }

    if (filters.depthBottomCenterExtraBottom) {
      queryParts.push(
        "depthBottomCenterExtraBottom=" + filters.depthBottomCenterExtraBottom
      );
    }

    if (filters.sides) {
      queryParts.push("sides=" + filters.sides);
    }

    if (filters.vs) {
      queryParts.push("vs=" + filters.vs);
    }

    if (filters.hs) {
      queryParts.push("hs=" + filters.hs);
    }

    if (filters.signedOnly != undefined) {
      queryParts.push("signed=" + filters.signed);
    }

    return "?" + queryParts.join("&");
  } */
}
