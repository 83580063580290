import { AuthorizeStep } from './../authorizeStep';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient, RequestInit } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AuthorizeError, ForbiddenError, ServerError } from 'lib/error';
import { AccountService } from 'services/account-service';

export type ResponseMeta<T> = {
  data: T;
  headers: Headers;
  status: number;
  statusText: string;
  url: string;
};

@autoinject
export class AuthHttpClient {
  constructor(
    public httpClient: HttpClient,
    private accountService: AccountService,
    private router: Router,
    private eventAggregator: EventAggregator
  ) {
    this.httpClient.configure((config) => {
      const self = this;
      config.withInterceptor({
        response(response) {
          if (response.status === 401) {
            self.accountService.clearAuthToken();
            self.eventAggregator.publish('isLoggedIn', false);
            self.router.navigateToRoute('login');
            AuthorizeStep.auth.isAuthenticated = false;
          }
          return response;
        },
      });
    });

    this.setupAuthHeaders();

    const subscriptionLogin = this.eventAggregator.subscribe('auth_token_updated', (response: any) => {
      this.setupAuthHeaders();
    });

    const subscriptionLogout = this.eventAggregator.subscribe('auth_token_cleared', (response: any) => {
      this.clearAuthHeaders();
    });
  }

  /**
   * Creates an error class instance based on the http status, with provided message.
   *
   * @param {number} httpStatus - the http status to generate error for
   * @param {string} [message] - message to addd to error object
   */
  protected createError(httpStatus: number, message?: string) {
    const messageFormat = message ? ': ' + message : '';
    console.log('createError', httpStatus, message);

    if (httpStatus === 400) {
      return new AuthorizeError(`Invalid data${messageFormat}`);
    }
    if (httpStatus === 401) {
      return new AuthorizeError(`Requires authentication${messageFormat}`);
    }

    if (httpStatus === 403) {
      return new ForbiddenError(`Forbidden to perform action${messageFormat}`);
    }

    if (httpStatus === 404) {
      return new AuthorizeError(`Not found${messageFormat}`);
    }

    return new ServerError(`An unexpected server error occurred${messageFormat}`);
  }

  public setupAuthHeaders() {
    if (this.accountService.getAuthToken()) {
      this.httpClient.configure((config) => {
        config.withDefaults({
          headers: {
            Authorization: 'Bearer ' + this.accountService.getAuthToken(),
            pragma: 'no-cache',
            'cache-control': 'no-cache',
          },
        });
      });
    }
  }

  public clearAuthHeaders() {
    this.httpClient.configure((config) => {
      config.withDefaults({
        headers: {},
      });
    });
  }

  // proxy for the HttpClient.fetch to simplify usage in the services using AuthHttpClient
  public fetch(input: string, request: RequestInit) {
    return this.httpClient.fetch(input, request);
  }

  public async execWithResponseMeta<T>(url: string, request: RequestInit) {
    let headers: object = {
      'Content-Type': request?.headers?.['Content-Type'] || 'application/json',
      Accept: 'application/json',
    };

    if (request?.headers) {
      headers = request.headers as Headers;
    }

    const response = await this.httpClient.fetch(url, {
      ...request,
      headers,
    });
    if (response.ok) {
      return {
        data: (await response.json()) as T,
        headers: response.headers,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      };
    } else {
      throw this.createError(response.status, await response.text());
    }
  }

  public async exec<T>(url: string, request: RequestInit) {
    return (await this.execWithResponseMeta<T>(url, request)).data;
  }

  public async execNoTransform(url: string, request: RequestInit) {
    const response = await this.httpClient.fetch(url, request);
    if (response.ok) {
      return response;
    } else {
      throw this.createError(response.status, await response.text());
    }
  }
}
