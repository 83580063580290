import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class DesignTypeSideRopeCombinationService extends BaseApiService<Models.DesignTypeSideRopeCombination> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.DesignTypeSideRopeCombination);
    }

    public getValidCombinations(netId: number): Promise<Array<any>> {
        return super.getAll('/get-valid-sides-sideropes/' + netId);
    }
}
