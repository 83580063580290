import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService, BaseFilter } from 'services/base-api-service';

export interface CustomerServiceFilter extends BaseFilter {
  searchText?: string;
  industryId?: number | string;
}

@autoinject
export class CustomerService extends BaseApiService<Models.Customer> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.Customer);
  }

  public getList(customerServiceFilter: CustomerServiceFilter): Promise<any> {
    const params = this.createBaseFilters(customerServiceFilter);
    params.append('$expand', 'Industry');

    if (
      customerServiceFilter.searchText ||
      !!customerServiceFilter.industryId
    ) {
      const filterBase = '$filter';
      let first = true;
      let filter = '';

      if (customerServiceFilter.searchText) {
        filter += `contains(Name, '${customerServiceFilter.searchText}')`;
        first = false;
      }
      if (!!customerServiceFilter.industryId) {
        if (!first) {
          filter += ' and ';
        }
        filter += `IndustryId eq ${customerServiceFilter.industryId}`;
      }
      if (filter) {
        params.append(filterBase, filter);
      }
    }

    return this.getResponse('?' + params.toString());
  }

  public validateCustomer(customer: Models.Customer): Promise<any> {
    return this.post(customer, '/validate-customer');
  }

  public mergeCustomers(mergedata: any): Promise<any> {
    return this.post(mergedata, '/merge-customers');
  }

  public checkCanDelete(id: number): Promise<any> {
    return this.get('check-can-delete/' + id);
  }
}
