import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class DesignTypeService extends BaseApiService<Models.DesignType> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.DesignType);
  }

  public getListWithDefaultNetShape(): Promise<any> {
    let params = new URLSearchParams();

    params.append('$expand', 'DefaultNetShape');

    return this.get('?' + params.toString());
  }
}

export enum DesignTypeIds {
  CylindricalStraightSides = 11,
  CylindricalConeBottom = 12,
  CylindricalIntegratedSinkerTube = 13,
  ConedNet = 14,
  HalfConedNetForSinkerTube = 15,
  HalfConedNetCenterWeightSpagetthi = 16,
  SquareNet = 17,
  SquareNetCuttedCorners = 18,
  RectangularNet = 19,
  RectangularNetCuttedCorners = 20,
}
