import { autoinject } from 'aurelia-framework';
import { ConnectionType } from 'models/Connections';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ConnectionTypeService extends BaseApiService<ConnectionType> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ConnectionType);
  }
}
