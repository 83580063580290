import { autoinject } from 'aurelia-framework';
import { DesignTypeDrawing } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';

@autoinject
export class DesignTypeDrawingService {
  private readonly baseUrl = 'api/design-type-drawing';
  private readonly uploadUrl = this.baseUrl + '/upload';
  private readonly deleteByFileIdUrl = (fileId: number | string) => this.baseUrl + `/file/${fileId}`;

  constructor(private httpClient: AuthHttpClient) {}

  public upload(designTypeId: number, file: File) {
    const formData = new FormData();

    formData.append('files', file, file.name ? file.name : 'files');
    formData.append('designTypeId', designTypeId.toString());

    return this.httpClient.exec<DesignTypeDrawing>(this.uploadUrl, {
      method: 'POST',
      body: formData,
      headers: {},
    });
  }

  public async deleteByFileId(fileId: string | number) {
    await this.httpClient.execNoTransform(this.deleteByFileIdUrl(fileId), {
      method: 'DELETE',
      headers: {},
    });
  }
}
