import { NetlogHttpClient } from 'lib/http';
import { FileAttachment, CustomerDocumentType } from 'models';
import { AttachmentHandler, FileId } from './attachment-handler';

export type CustomerDocumentsHandlerArgs = {
  customerId: number;
  documentType: CustomerDocumentType;
};

export class CustomerDocumentsHandler implements AttachmentHandler {
  private customerId: number;
  private documentType: CustomerDocumentType;

  constructor(
    private client: NetlogHttpClient,
    args: CustomerDocumentsHandlerArgs
  ) {
    if (!args.customerId) {
      throw new Error('customerId is required');
    }

    this.customerId = args.customerId;
    this.documentType = args.documentType;
  }

  get baseUrl() {
    return `api/customers/${this.customerId}/files`;
  }

  get documentsUrl() {
    return `${this.baseUrl}/documents?documentType=${this.documentType}`;
  }

  upload(file: File) {
    const form = this.client.fileFormdata(file);
    return this.client.uploadFile(form, this.documentsUrl);
  }

  delete(storageFileId: FileId) {
    return this.client.delete({
      url: `${this.baseUrl}/${storageFileId}/documents`,
    });
  }

  download(storageFileId: FileId) {
    return this.client.downloadBlob(`${this.baseUrl}/${storageFileId}`);
  }

  async metadata() {
    const response = await this.client.get<FileAttachment[]>(
      {
        url: `api/customers/${this.customerId}/files/documents?documentType=${this.documentType}`,
        model: FileAttachment,
      },
      { cache: false }
    );
    return response.data;
  }
}
