import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class AddressService extends BaseApiService<Models.Address> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.Address);
    }

    public addFullAddressProperty(addresses: Array<Models.Address>) {
        addresses.forEach(a => {
            a['FullAddress'] = a.Address1;
            if (a.Address2) {
              a['FullAddress'] += ', ' + a.Address2;
            }
            if (a.Zip) {
              a['FullAddress'] += ', ' + a.Zip + ' ' + a.City;
            }
          });
        return addresses; 
    }
}
