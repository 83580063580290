import { autoinject } from 'aurelia-framework';
import { Coupling } from 'models/Connections';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class CouplingService extends BaseApiService<Coupling> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Coupling);
  }
}
