import { Appender, Logger } from 'aurelia-logging';
import * as Sentry from '@sentry/browser';
import { LogManager } from 'aurelia-framework';
import { safeJoin } from '@sentry/utils';
type LEVELS = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';

export class SentryAppender implements Appender {
  private logLevel: number;

  constructor(level?: string) {
    this.logLevel = level ? LogManager.logLevel[level] : undefined;

    if (this.logLevel === undefined) {
      this.logLevel = LogManager.logLevel.debug;
    }

    if (!this.isEnabled(LogManager.logLevel.debug)) {
      this.debug = this.sentryBreadcrumbFactory('debug');
    }

    if (!this.isEnabled(LogManager.logLevel.info)) {
      this.info = this.sentryBreadcrumbFactory('info');
    }

    if (!this.isEnabled(LogManager.logLevel.warn)) {
      this.debug = this.sentryBreadcrumbFactory('warning');
    }

    if (!this.isEnabled(LogManager.logLevel.error)) {
      this.error = this.sentryBreadcrumbFactory('error');
    }
  }

  debug(logger: Logger, ...rest: any[]): void {
    console.debug(`DEBUG [${logger.id}]`, ...rest);
  }

  info(logger: Logger, ...rest: any[]): void {
    console.debug(`INFO [${logger.id}]`, ...rest);
  }

  warn(logger: Logger, ...rest: any[]): void {
    console.debug(`WARN [${logger.id}]`, ...rest);
  }

  error(logger: Logger, ...rest: any[]): void {
    console.debug(`ERROR [${logger.id}]`, ...rest);
  }

  private sentryBreadcrumbFactory(level: LEVELS) {
    return function (logger: Logger, ...rest: any[]) {
      const args = [`${levelText[level]} [${logger.id}]`, ...rest];

      const breadcrumb = {
        category: 'console',
        data: {
          arguments: args,
          logger: 'console',
        },
        level: level,
        message: safeJoin(args, ' '),
      };

      Sentry.addBreadcrumb(breadcrumb);
    };
  }

  private isEnabled = (logLevel) => logLevel <= this.logLevel;
}

const levelText = {
  error: 'ERROR',
  warning: 'WARN',
  info: 'INFO',
  debug: 'DEBUG',
};
