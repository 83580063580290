import { RequestInit } from 'aurelia-fetch-client';
import { AuthHttpClient } from 'services/auth-http-client';
import { saveAs } from 'file-saver';
import { AuthorizeError, ForbiddenError, ServerError } from 'lib/error';

export interface BaseFilter {
  orderBy: string;
  top: number;
  skip: number;
  expand?: string;
  count?: boolean;
}

const OdataParameters = new Set<keyof BaseFilter>(['orderBy', 'top', 'skip', 'expand', 'count']);

export class BaseApiService<T> {
  protected apiUrl: string;

  protected cachedObjects: Array<T> = null;
  protected isRetrievingCache = false;

  constructor(
    protected httpClient: AuthHttpClient,
    protected type: { new (): T }
  ) {
    if (type) {
      if ((type as any).ApiUrl) {
        this.apiUrl = (type as any).ApiUrl.replace('/{id}', '');
      }
    }
  }

  /**
   * Creates an error class instance based on the http status, with provided message.
   *
   * @param {number} httpStatus - the http status to generate error for
   * @param {string} [message] - message to addd to error object
   */
  protected createError(httpStatus: number, message?: string) {
    const messageFormat = message ? ': ' + message : '';

    if (httpStatus === 400) {
      return new AuthorizeError(`Invalid data${messageFormat}`);
    }
    if (httpStatus === 401) {
      return new AuthorizeError(`Requires authentication${messageFormat}`);
    }

    if (httpStatus === 403) {
      return new ForbiddenError(`Forbidden to perform action${messageFormat}`);
    }

    if (httpStatus === 404) {
      return new AuthorizeError(`Not found${messageFormat}`);
    }

    return new ServerError(`An unexpected server error occurred${messageFormat}`);
  }

  public createBaseFilters(filter: BaseFilter, odata = true) {
    const params = new URLSearchParams();
    if (odata) {
      for (const [key, value] of Object.entries(filter)) {
        if (value && OdataParameters.has(key as keyof BaseFilter)) {
          params.append(`${odata ? '$' : ''}${key}`, value);
        }
      }
    }

    return params;
  }

  public get(id: number | string): Promise<T> {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as RequestInit;

    return this.httpClient.fetch(this.apiUrl + '/' + id, options).then((response: Response) => {
      const status = response.status;
      if (status < 400) {
        return response.text().then((responseText) => {
          if (responseText) {
            return JSON.parse(responseText) as T;
          }
          return null;
        });
      }

      return response.text().then((responseText) => {
        throw this.createError(status, responseText);
      });
    });
  }

  public getResponse(id: number | string | boolean = false) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as RequestInit;

    const suffix = id ? '/' + id : '';

    return this.httpClient.fetch(this.apiUrl + suffix, options).then((response) => {
      const status = response.status;
      if (status < 400) {
        return response;
      }

      return response.text().then((responseText) => {
        throw this.createError(status, responseText);
      });
    });
  }

  public getAll(urlParams: string = null): Promise<Array<T>> {
    if (urlParams) {
      urlParams = urlParams.replace(/[?&]$/, '');

      if (urlParams.indexOf('?') !== 0 && urlParams.indexOf('/') !== 0) {
        urlParams = '?' + urlParams;
      }
    }

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as RequestInit;

    return this.httpClient.fetch(this.apiUrl + (urlParams ? urlParams : ''), options).then((response: Response) => {
      const status = response.status;
      if (status < 400) {
        return response.text().then((responseText) => {
          return JSON.parse(responseText) as Array<T>;
        });
      }

      return response.text().then((responseText) => {
        throw this.createError(status, responseText);
      });
    });
  }

  public async getAllCached(): Promise<Array<T>> {
    // if the cache is already filled, return that - if not, fill the cache and return it
    if (this.isRetrievingCache) {
      // wait a couple of seconds to avoid running multiple requests at the same time
      let attempts = 1;

      while (attempts < 20) {
        await this.sleep(10 * attempts);
        if (this.cachedObjects != null) {
          break;
        }
        attempts += 1;
      }
    }

    // if the cache is already filled, return that - if not, fill the cache and return it
    if (this.cachedObjects) {
      return Promise.resolve(this.cachedObjects);
    }

    this.isRetrievingCache = true;

    return this.getAll('/getallwithdeleted')
      .then((res) => {
        this.cachedObjects = res;
        this.isRetrievingCache = false;
        return res;
      })
      .catch((err) => {
        this.isRetrievingCache = false;
        throw err;
      });
  }

  public post(entity: T, url: string = null): Promise<T> {
    if (url) {
      url = url.replace(/[?&]$/, '');
    }

    // clear cached objects if any changes are made to an object of this type
    if (this.cachedObjects) {
      this.cachedObjects = null;
    }

    const options = {
      body: JSON.stringify(entity),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as RequestInit;

    return this.httpClient.fetch(this.apiUrl + (url ? url : ''), options).then((response: Response) => {
      const status = response.status;
      if (status < 400) {
        return response.text().then((responseText) => {
          if (responseText) {
            return JSON.parse(responseText) as T;
          } else {
            return null;
          }
        });
      }

      return response.text().then((responseText) => {
        throw this.createError(status, responseText);
      });
    });
  }

  public put(entity: T, id?: number | string, url: string = null): Promise<T> {
    if (url) {
      url = url.replace(/[?&]$/, '');
    }

    // clear cached objects if any changes are made to an object of this type
    if (this.cachedObjects) {
      this.cachedObjects = null;
    }

    const options = {
      body: JSON.stringify(entity),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as RequestInit;

    return this.httpClient.fetch(this.apiUrl + (url ? url : id ? '/' + id : ''), options).then((response: Response) => {
      const status = response.status;
      if (status < 400) {
        return response.text().then((responseText) => {
          if (responseText) {
            return JSON.parse(responseText) as T;
          } else {
            return null;
          }
        });
      }

      return response.text().then((responseText) => {
        throw this.createError(status, responseText);
      });
    });
  }

  public delete(id: number | string, url: string = null): Promise<any> {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as RequestInit;

    // clear cached objects if any changes are made to an object of this type
    if (this.cachedObjects) {
      this.cachedObjects = null;
    }

    return this.httpClient.fetch(this.apiUrl + (url ? url : id ? '/' + id : ''), options).then((response: Response) => {
      const status = response.status;
      if (status < 400) {
        return response.text().then((responseText) => {
          if (responseText) {
            return JSON.parse(responseText) as T;
          } else {
            return null;
          }
        });
      } else {
        return response.text().then((responseText) => {
          throw this.createError(status, responseText);
        });
      }
    });
  }

  public sleep(ms): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public getBlob(url: string): Promise<any> {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'Blob',
        Accept: 'application/json',
      },
    } as RequestInit;

    return this.httpClient.fetch(this.apiUrl + '/' + url, options).then((response: Response) => {
      const status = response.status;
      if (status < 400) {
        let filename = '';
        const disposition = response.headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        return response.blob().then((blob) => {
          saveAs(blob, filename);
        });
      } else {
        return response.text().then((responseText) => {
          throw this.createError(status, responseText);
        });
      }
    });
  }

  compareArrays(array1: Array<any>, array2: Array<any>, propertyToCompare: string) {
    if (!array1 || !array2) {
      return false;
    }

    if (array1.length != array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i][propertyToCompare] !== array2[i][propertyToCompare]) {
        return false;
      }
    }

    return true;
  }
}
